#breadcrumbs ol:first-child {
    padding-left: 8px;
}

#breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

#breadcrumbs li:not(:last-child)::after {
    display: inline-block;
    margin: 0 .25rem;
    content: "/";
}

#navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#navigation ul li {
    float: left;
}

#navigation li a {
    display: block;
    padding: 8px;
}

nav[aria-label=pagination] {
    display: flex;
    justify-content: center;
}

nav[aria-label=pagination] ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav[aria-label=pagination] ol li {
    margin: 0 1px;
}

nav[aria-label=pagination] ol li a, nav[aria-label=pagination] ol span {
    display: block;
    padding: .5em 1em;
    border: 1px solid var(--border);
    border-radius: .2em;
    text-decoration: none;
}

nav[aria-label=pagination] ol li a[aria-current=true] {
    background-color: var(--background);
}

body {
    max-width: none;
    min-height: 100vh; margin:0;
    display:flex; flex-direction:column;
}

footer {
    min-height:2rem;
    text-align: center;
}

section {
    flex:1;
}

body+img {
    display: inline;
}

footer img {
    /*filter: invert(1);*/
}

/* Order By */
/*section form input[type="submit"] {*/
/*    display: inline;*/
/*}*/

/*section form {*/
/*    text-align: center;*/
/*}*/

@media (max-width: 850px) {
    body section nav ol li.hidden-mobile {
        display: none;
    }
}

/* Admin */
#bookmark_tags {
    display: block;
    width: 100%;
    height: auto;
}

#bookmark_tags option {
    display: flow-root;
    padding: 4px;
    float: left;
}

#orderBy * {
    display: inline;
}

#orderBy label {
    margin-right: 0;
}

#orderBy label select {
    margin-right: -5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 6px;
}

#orderBy input {
    margin-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 0;
}

#bookmark_url, #bookmark_title {
    width: 99%;
}

#navigation {
}

#breadcrumbs {
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}
.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
